import * as React from 'react';
import {Header as B2CHeader} from '@edekadigital/b2c-components';

export const Header: React.FC = () => {
  const LogoImageProps = {
    src: '/assets/images/logo_edeka.png',
    alt: 'EDEKA Logo',
    sources: [
      {
        srcSet:
          '/assets/images/logo_edeka_hdpi.png 2x, /assets/images/logo_edeka.png',
      },
    ],
  };
  return <B2CHeader LogoImageProps={LogoImageProps} />;
};
