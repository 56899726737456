// "unknown" is used for testing
export type Host = 'localhost' | 'dev' | 'test' | 'prod' | 'unknown';

type Environment = {
  [key in Host]: string[];
};

const environments: Environment = {
  localhost: ['^localhost:', '^127.0.0.1'],
  dev: ['^dev.login.edeka$'],
  test: ['^test.login.edeka$'],
  prod: ['^login.edeka$'],
  unknown: [''],
};

export const getEnvironment = (host: string): Host => {
  let environment: Host = 'unknown';
  Object.entries(environments).some(([k, values]) => {
    values.some(v => {
      if (host?.match(v)) {
        environment = k as Host;
        return true;
      }
      return false;
    });
    return environment !== 'unknown';
  });

  return environment;
};
