import * as React from 'react';
import {Footer as B2CFooter} from '@edekadigital/b2c-components';
import {LinkItem} from '@edekadigital/b2c-components/lib/types/components/Footer';
import {
  LINK_IMPRINT,
  ROUTE_DATA_PROTECTION_NOTES,
} from '../../common/constants';

export const Footer: React.FC = () => {
  const linkList: LinkItem[] = [
    {
      label: 'Datenschutzerklärung',
      href: ROUTE_DATA_PROTECTION_NOTES,
      target: '_blank',
      hideInWebView: true,
    },
    {
      label: 'Impressum',
      href: LINK_IMPRINT,
      target: '_blank',
      hideInWebView: true,
    },
  ];
  const ClaimImageProps = {
    src: '/assets/images/claim.svg',
    alt: 'Wir ❤ Lebensmittel',
  };
  return <B2CFooter linkList={linkList} ClaimImageProps={ClaimImageProps} />;
};
