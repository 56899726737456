import * as React from 'react';
import {
  B2CComponentsProvider,
  Body,
  Heading,
  SSOPage,
} from '@edekadigital/b2c-components';
import {Footer, Header} from '../components';
import {RouteComponentProps} from '@gatsbyjs/reach-router';
import {MESSAGE_PAGE_NOT_FOUND} from '../common/constants';
import EdekaTitle from '../components/commons/Title';

export const Head = EdekaTitle;
export const NotFound: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <div>
        <Heading gutterBottom={true}>Seite nicht gefunden</Heading>
        <Body data-testid="not-found">
          {MESSAGE_PAGE_NOT_FOUND}
          <p>
            <a href={'/'}>Zurück zur Startseite</a>
          </p>
        </Body>
      </div>
    </>
  );
};

const NotFoundPage: React.FC<RouteComponentProps> = () => {
  const header = <Header />;
  const footer = <Footer />;
  return (
    <B2CComponentsProvider>
      <SSOPage footer={footer} header={header}>
        <NotFound />
      </SSOPage>
    </B2CComponentsProvider>
  );
};

export default NotFoundPage;
