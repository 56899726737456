import * as React from 'react';
import {
  BrandPanel as B2CBrandPanel,
  Heading,
} from '@edekadigital/b2c-components';

export const BrandPanel: React.FC = () => {
  const ImageProps = {
    src: '/assets/images/brand-panel_1200x1800.jpg',
    alt: '',
    sources: [
      {
        media: '(min-width: 960px)',
        srcSet: '/assets/images/brand-panel_1200x1800.jpg',
      },
      {
        media: '(min-width: 600px)',
        srcSet: '/assets/images/brand-panel_1920x800.jpg',
      },
      {
        srcSet: '/assets/images/brand-panel_1200x675.jpg',
      },
    ],
  };

  return (
    <B2CBrandPanel ImageProps={ImageProps}>
      <Heading component="div" variant="h2" align="center">
        Einmal anmelden.
      </Heading>
      <Heading component="div" variant="h4" align="center">
        Alle EDEKA-Dienste nutzen.
      </Heading>
    </B2CBrandPanel>
  );
};
